// Generated by Framer (06534cb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const enabledGestures = {cMX2I_h6s: {hover: true}};

const cycleOrder = ["cMX2I_h6s", "z_63KEXPW"];

const serializationHash = "framer-nMf13"

const variantClassNames = {cMX2I_h6s: "framer-v-s3x9hg", z_63KEXPW: "framer-v-7hi51y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 50, delay: 0, mass: 1, stiffness: 300, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Default: "cMX2I_h6s", Selected: "z_63KEXPW"}

const getProps = ({click, height, id, link, title, width, ...props}) => { return {...props, FcK_TvsYr: title ?? props.FcK_TvsYr ?? "Home", QuWKPWgPR: link ?? props.QuWKPWgPR, SH0a5UkvE: click ?? props.SH0a5UkvE, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "cMX2I_h6s"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, FcK_TvsYr, QuWKPWgPR, SH0a5UkvE, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "cMX2I_h6s", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapxt6f5i = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (SH0a5UkvE) {const res = await SH0a5UkvE(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={QuWKPWgPR} motionChild nodeId={"cMX2I_h6s"} openInNewTab={false} scopeId={"JkN7FqZcz"} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-s3x9hg", className, classNames)} framer-1yj4nqw`} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"cMX2I_h6s"} onTap={onTapxt6f5i} ref={refBinding} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} variants={{"cMX2I_h6s-hover": {backgroundColor: "var(--token-6839e435-35f3-4ab3-b723-e98c27e525bc, rgba(255, 255, 255, 0.1))"}, z_63KEXPW: {backgroundColor: "var(--token-6839e435-35f3-4ab3-b723-e98c27e525bc, rgba(255, 255, 255, 0.1))"}}} {...addPropertyOverrides({"cMX2I_h6s-hover": {"data-framer-name": undefined}, z_63KEXPW: {"data-framer-name": "Selected"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-font-size": "14px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(158, 161, 186))"}}>Home</motion.p></React.Fragment>} className={"framer-ruk9pd"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"jP6e2cgu8"} style={{"--extracted-r6o4lv": "rgb(158, 161, 186)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={FcK_TvsYr} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nMf13.framer-1yj4nqw, .framer-nMf13 .framer-1yj4nqw { display: block; }", ".framer-nMf13.framer-s3x9hg { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 5px 8px 5px 8px; position: relative; text-decoration: none; width: min-content; }", ".framer-nMf13 .framer-ruk9pd { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-nMf13.framer-s3x9hg { gap: 0px; } .framer-nMf13.framer-s3x9hg > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-nMf13.framer-s3x9hg > :first-child { margin-left: 0px; } .framer-nMf13.framer-s3x9hg > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 55
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"z_63KEXPW":{"layout":["auto","auto"]},"aYT3qDH_D":{"layout":["auto","auto"]}}}
 * @framerVariables {"FcK_TvsYr":"title","QuWKPWgPR":"link","SH0a5UkvE":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerJkN7FqZcz: React.ComponentType<Props> = withCSS(Component, css, "framer-nMf13") as typeof Component;
export default FramerJkN7FqZcz;

FramerJkN7FqZcz.displayName = "nav items";

FramerJkN7FqZcz.defaultProps = {height: 27, width: 55};

addPropertyControls(FramerJkN7FqZcz, {variant: {options: ["cMX2I_h6s", "z_63KEXPW"], optionTitles: ["Default", "Selected"], title: "Variant", type: ControlType.Enum}, FcK_TvsYr: {defaultValue: "Home", displayTextArea: false, title: "Title", type: ControlType.String}, QuWKPWgPR: {title: "Link", type: ControlType.Link}, SH0a5UkvE: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerJkN7FqZcz, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})